/* eslint-disable no-debugger */
import './NavBar.css'
import React, { useState, useEffect } from 'react'
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';

import connectButtonClicked from '../../assets/buttons/Connect-clicked.svg'
import connectButtonUnclicked from '../../assets/buttons/Connect-nonclick.svg'
import metazillaLogo from '../../assets/KaijuKingz-500px-w.png'
import discordLogo from '../../assets/socials-logos/Discord Logo.svg'
import twitterLogo from '../../assets/socials-logos/Twitter Logo.svg'
import openseaLogo from '../../assets/socials-logos/opensea-logo.svg'

import { HashLink } from 'react-router-hash-link';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { requestAccounts } from '../../util/ethers-helper';

const NavBar = () => {
  let autoConnect;

  useEffect(() => {
    autoConnect = localStorage.getItem('autoConnect')
    if (autoConnect === 'true') {
      handleButtonClick()
    }
  }, [])

  const history = useHistory();
  const walletConnection = useSelector(state => state.walletConnection)
  let [, setState] = useState(); // call setState in order to force a rerender

  const handleButtonClick = async () => {
    await requestAccounts();    //fires redux action - this requestsAccount from user - then handleAccounts function is called on response - if connection successful, store is updated with kaijuCignerContract, provider, and signer
  }

  return (
    <>
      <Navbar className='color-nav' expand="lg" sticky="top">
        <Container>
          <Navbar.Brand onClick={() => { history.push('/'); setState({}); }} style={{ cursor: 'pointer' }}><img loading="lazy" id='navLogo' src={metazillaLogo} /></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="navLinks">
              <HashLink className='navbar-items' smooth to="/#mint">MINT</HashLink>
              <HashLink className='navbar-items' smooth to="/#about">ABOUT</HashLink>
              <HashLink className='navbar-items' smooth to="/#team">TEAM</HashLink>
              <a className='navbar-items' onClick={() => { history.push('/RWaste'); setState({}); }} style={{ cursor: 'pointer' }}>RWASTE</a>
              {walletConnection && <a className='navbar-items' onClick={() => { history.push('/myKaijuz'); setState({}); }} style={{ cursor: 'pointer' }}>MY KAIJUZ</a>}
            </Nav>

            <Nav className='rightside-nav'>
              <Nav.Link target="_blank" href="https://discord.gg/kaiju-kingz"><img loading="lazy" className='socialLogos' src={discordLogo} /></Nav.Link>
              <Nav.Link target="_blank" href="https://twitter.com/kaijukingz/"><img loading="lazy" className='socialLogos' src={twitterLogo} /></Nav.Link>
              <Nav.Link target="_blank" href="https://opensea.io/collection/kaiju-kingz"><img loading="lazy" className='socialLogos' src={openseaLogo} /></Nav.Link>
              {/* <img loading="lazy" style={{height:'3rem'}} src={connectButton} onClick={handleButtonClick} /> */}
              {(walletConnection) ? <img loading="lazy" className='connectButton' src={connectButtonClicked} onClick={handleButtonClick} style={{ cursor: 'not-allowed' }} /> : <img loading="lazy" className='connectButton' src={connectButtonUnclicked} onClick={handleButtonClick} style={{ cursor: 'pointer' }} />}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  )
}

export default NavBar;

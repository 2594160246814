import {lazy, Suspense, React} from 'react';
import { Route, Switch } from 'react-router-dom';
import LoadingContainer from '../LoadingContainer/LoadingContainer';
const LandingPage = lazy(() => import ('../../containers/LandingPage/LandingPage'))
const MintPage = lazy(() => import ('../../containers/MintPage/MintPage'))
const MyZillazContainerPage = lazy(() => import ('../MyZillas/MyZillazContainerPage'))
const AboutPage = lazy(() => import ('../../containers/AboutPage/AboutPage'))
const TeamPage = lazy(() => import ('../../containers/TeamPage/TeamPage'))
const UtilityPage = lazy(() => import ('../../containers/UtilityPage/UtilityPage'))
const ZillaPage = lazy(() => import ('../../containers/ZillaPage/ZillaPage'))
const RWastePage = lazy(() => import ('../../containers/RWastePage/RWastePage'))
const RoadmapPage = lazy(() => import ('../../containers/RoadmapPage/RoadmapPage'))
const Community = lazy(() => import ('../../components/Community/Community'))
const Footer = lazy(() => import ('../../components/Footer/Footer'))



export default function Routes() {

  return (
    <Switch>
      <Suspense fallback={<LoadingContainer bg='black'/>}>
        <Route exact path='/'>
          <LandingPage />
          <MintPage />
          <AboutPage />
          <UtilityPage />
          <RoadmapPage />
          <TeamPage />
          <Community />
          <Footer />
        </Route>

        <Route exact path='/RWaste'>
          <RWastePage />
          <Community />
          <Footer />
        </Route>

        <Route exact path='/myKaijuz'>
          <MyZillazContainerPage />
          <Community />
          <Footer />
        </Route>

        {/* <Route exact path='/profile/:walletID'>
          <ProfilePage />
        </Route> */}
        <Route path="/kaiju/:id">
          <ZillaPage />
          <Community />
          <Footer />
        </Route>
      </Suspense>
    </Switch>
  );
}


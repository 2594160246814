/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
export function rootReducer(
    state = {
      signer: null,
      provider: null,
      kaijuContract: null,
      rwasteContract: null,
      walletConnection: null,
      breedingQueue: [],
      loadingTokenData: true,
      tokenData: null,
      userInfo:{
        walletAddress: null,
        userTokens:[],
      },
      userTokensMetaData:[],
      userTokensKaijus:[],
      userTokensBabies:[],
    }, action
  ) {
    let tempBreedingQueue = [];
  
    switch (action.type) {
      case 'SET_SIGNER':
        return {
          ...state,
          signer: action.signer
        }
      case 'SET_PROVIDER':
        return {
          ...state,
          provider: action.provider
        }
      case 'SET_KAIJU_CONTRACT':
        return {
          ...state,
          kaijuContract: action.kaijuContract
        }
      case 'SET_RWASTE_CONTRACT':
        return {
          ...state,
          rwasteContract: action.rwasteContract
        }
      case 'SET_USERS_TOKENS':
        return {
          ...state,
          usersTokens: action.usersTokens
        }
      case 'SET_WALLET_CONNECTION':
        return {
          ...state,
          walletConnection: action.connection
        }
      case 'CLEAR_BREEDING_QUEUE':
        return {
          ...state,
          breedingQueue: []
        }
      case 'ADD_TO_BREEDING_QUEUE':
        tempBreedingQueue = [...state.breedingQueue]
        tempBreedingQueue.push(action.kaiju) //cannot push and assign at the same time bc push returns length of array and not the array
        return {
          ...state,
          breedingQueue: [...tempBreedingQueue]
        }
        case 'REMOVE_FROM_BREEDING_QUEUE':
          let index;// eslint-disable-line no-case-declarations
          tempBreedingQueue = [...state.breedingQueue]; // eslint-disable-line no-case-declarations
          for(let i=0;i<tempBreedingQueue.length;i++){
            if(tempBreedingQueue[i].tokenId === action.kaiju.tokenId){
              index = i
            }
          }
          // const index = tempBreedingQueue.indexOf(action.kaiju); // eslint-disable-line no-case-declarations
          tempBreedingQueue.splice(index, 1);
          return {
            ...state,
            breedingQueue: [...tempBreedingQueue]
          }
        case 'SET_USER_INFO':
          return {
            ...state,
            userInfo: Object.assign(state.userInfo, action.userInfo) // spread old userInfo and spread new user info and combine | spread to remain immutability
          }

        case 'CHANGE_LOADING_STATE_TO':
          return{
            ...state,
            loadingTokenData: action.bool
          }
        // case 'ADD_TOKEN_DATA':
        //   // eslint-disable-next-line no-debugger  
        //   debugger;
        //   return{
        //     ...state,
        //     loadingTokenData: false,
        //     userInfo: Object.assign({...state.userInfo}, {zillaTokensData: [...state.userInfo.zillaTokensData, action.data]}),
        //     userTokensMetaData: Object.assign([...state.userTokensMetaData], [...action.data])
        //   }
        case 'FETCHED_USER_TOKENS_METADATA':
          return{
            ...state,
            loadingTokenData: false,
            userTokensMetaData: action.ownedKaijuzMetaData,
            userTokensKaijus:action.kaijus,
            userTokensBabies:action.babies
          }
      default:
        return state
    }
  }
  
  export default rootReducer;
  
import React from 'react';
import NavBar from './components/NavBar/NavBar';
import Routes from './components/Routes/Routes';
import './App.css';

function App() {
  React.useEffect(() => {
    window.location.href = "https://origins.kaijukingz.io/";
  }, []);
  return (
    <>
      <NavBar />
      <Routes />
    </>
  );
}

export default App;

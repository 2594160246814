import React from 'react';
import './LoadingContainer.css';
import loadingGif from '../../assets/newLoadingGif.gif'

const LoadingContainer = ({bg}) => {
  return (
    <div className="main">
      <div style={{backgroundColor:bg}}className="loading-main">
        <div className='loading-page-container'>
          <img src={loadingGif} className='loading-page-gif'/>
        </div>
      </div>
    </div>

  );
}

export default LoadingContainer;

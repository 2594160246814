export const SetSigner = (signer) => {
    return {
      type: 'SET_SIGNER',
      signer
    }
}

export const SetProvider = (provider) => {
    return {
        type: 'SET_PROVIDER',
        provider
    }
}

export const SetKaijuContract = (kaijuContract) => {
    return {
        type: 'SET_KAIJU_CONTRACT',
        kaijuContract
    }
}

export const SetRWasteContract = (rwasteContract) => {
    return {
        type: 'SET_RWASTE_CONTRACT',
        rwasteContract
    }
}

export const SetUsersTokens = (usersTokens) => {
    return {
      type: 'SET_USERS_TOKENS',
      usersTokens
    }
}

export const SetWalletConnection = (connection) => {
    return {
      type: 'SET_WALLET_CONNECTION',
      connection
    }
}

export const SetUserInfo = (walletAddress, userTokens) =>{
    let userInfo={
        walletAddress: walletAddress,
        userTokens:[...userTokens],
    }
    return{
        type: 'SET_USER_INFO',
        userInfo
    }
}